<template>
  <div class="relative inline-flex items-center">
    <slot />
    <Indicator
      v-if="typeof amount === 'number' && amount !== 0"
      :amount="amount"
      class="absolute -top-[0.2em] -right-[0.2em] md:-top-[0.5em] md:-right-[0.5em]"
      :background-color="computedColor"
    />
  </div>
</template>

<script setup lang="ts">
import Indicator from "@/components/atoms/Indicator";
import { computed } from "vue";

const props = defineProps<{
  amount?: number;
  color?: string;
}>();

const computedColor = computed(() => props.color || "black");
</script>
